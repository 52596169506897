import { ISwitch } from './Switch.types';
import { StyledFormComponentWrapper, StyledSwitch } from './Switch.styles';
import { useEffect, useState, FC, ChangeEvent } from 'react';

const Switch: FC<ISwitch> = ({
  error,
  footnote,
  tooltip,
  required,
  label,
  name,
  checked = false,
  noFootnote = false,
  onChange,
  className,
  ...props
}) => {
  const [checkedState, setCheckedState] = useState<boolean>(checked);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedState(event.target.checked);
    onChange && onChange(event, event.target.checked);
  };

  useEffect(() => {
    setCheckedState(checked);
  }, [checked]);

  return (
    <StyledFormComponentWrapper
      required={required}
      tooltip={tooltip}
      footnote={footnote}
      label={label}
      name={name}
      error={!!error}
      noFootnote={!!noFootnote}
      $checked={!!checkedState}
      className={className}
    >
      <StyledSwitch
        onChange={handleChange}
        checked={!!checkedState}
        {...props}
      />
    </StyledFormComponentWrapper>
  );
};

export default Switch;
