import * as Yup from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';
import { AnyObject, Maybe } from 'yup/lib/types';
import emitAlert from '../Toastify/Toastify';
import { TFunction } from 'i18next';

export const getYupErrors = (t: TFunction, e: Yup.ValidationError) => {
  const errors: { [key: string]: string }[] = [];

  if (e.inner) {
    e.inner.forEach((inner) => {
      errors.push(...getYupErrors(t, inner));
    });
  }

  (e.errors || []).forEach(
    (error: string | { key: string; values: object }) => {
      if (typeof error === 'string') {
        errors.push({
          [e.path || '']: t(error),
        });
      } else {
        errors.push({
          [e.path || '']: t(error.key, { ...error.values }),
        });
      }
    }
  );

  return errors;
};

export const handleYupSubmissionError = (
  e: Yup.ValidationError,
  t: TFunction,
  cb?: (errors: { [key: string]: string }) => void
) => {
  const yupErrors = getYupErrors(t, e);

  const errors = yupErrors.reduce((a, current) => {
    return { ...a, ...current };
  }, {});

  cb && cb(errors);

  let errorMessage = t('yup.generic.error');

  for (let key in errors) {
    errorMessage = errors[key];
    break;
  }

  emitAlert({
    message: errorMessage,
    duration: 4000,
    type: 'error',
  });
};

Yup.setLocale({
  mixed: {
    required: 'yup.mixed.required',
  },
  string: {
    email: 'yup.string.email',
    min: ({ min }) => ({ key: 'yup.string.min', values: { min } }),
    max: ({ max }) => ({ key: 'yup.string.max', values: { max } }),
  },
  number: {
    min: ({ min }) => ({ key: 'yup.number.min', values: { min } }),
    max: ({ max }) => ({ key: 'yup.number.max', values: { max } }),
  },
  array: {
    min: ({ min }) => ({ key: 'yup.array.min', values: { min } }),
    max: ({ max }) => ({ key: 'yup.arra.max', values: { max } }),
  },
  date: {
    min: ({ min }) => ({ key: 'yup.date.min', values: { min } }),
    max: ({ max }) => ({ key: 'yup.date.max', values: { max } }),
  },
});

const password = () =>
  Yup.string()
    .required('yup.password.string.required')
    .min(10, ({ min }) => ({ key: 'yup.password.string.min', values: { min } }))
    .matches(RegExp('(.*[a-z].*)'), 'yup.password.string.lower-char')
    .matches(RegExp('(.*[A-Z].*)'), 'yup.password.string.upper-char')
    .matches(RegExp('(.*\\d.*)'), 'yup.password.string.number-char');

const comparePassword = (ref?: string) =>
  Yup.string()
    .oneOf(
      [Yup.ref(ref || 'password'), null],
      'yup.compare-password.string.compare'
    )
    .required();

Yup.addMethod<Yup.StringSchema>(Yup.string, 'password', password);
Yup.addMethod<Yup.StringSchema>(Yup.string, 'comparePassword', comparePassword);

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    password(): RequiredStringSchema<TType, TContext>;
    comparePassword(ref?: string): RequiredStringSchema<TType, TContext>;
  }
}

export const YupGeneric = Yup;
