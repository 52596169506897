import { VoucherPackProvider } from '@/contexts/VoucherPack';
import VoucherPackEdit from '@/pages/Vouchers/VoucherPacksEdit/VoucherPacksEdit';
import { lazy } from 'react';
import { Outlet, Route } from 'react-router-dom';

const VoucherPacks = lazy(() => import('@/pages/Vouchers/VoucherPacks'));
const VoucherPacksCreate = lazy(
  () => import('@/pages/Vouchers/VoucherPacksCreate')
);
const VoucherPacksDetail = lazy(
  () => import('@/pages/Vouchers/VoucherPacksDetail')
);

const VOUCHER_ROUTES = (
  <Route
    path="/dashboard/voucher-packs"
    element={
      <VoucherPackProvider>
        <Outlet />
      </VoucherPackProvider>
    }
    key="/dashboard/voucher-packs"
  >
    <Route path="/dashboard/voucher-packs" element={<VoucherPacks />} />
    <Route
      path="/dashboard/voucher-packs/create"
      element={<VoucherPacksCreate />}
    />
    <Route
      path="/dashboard/voucher-packs/:id"
      element={<VoucherPacksDetail />}
    />
    <Route
      path="/dashboard/voucher-packs/:id/edit"
      element={<VoucherPackEdit />}
    />
  </Route>
);

export default VOUCHER_ROUTES;
