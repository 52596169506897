import styled from 'styled-components';
import { FormBox } from '@/styles/shared';
import { FormElementCSS } from '@/styles/mixins';
import DatePicker from '@/components/DatePicker';
import Switch from '@/components/Switch';

export const Wrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 128px;
`;

export const ContentBox = styled(FormBox)`
  margin-top: 20px;
`;

export const StyledDatePicker = styled(DatePicker)<{ $width: string }>`
  ${(props) =>
    FormElementCSS({
      width: props.$width,
      mdBreakpoint: props.theme.breakpoints.md,
    })}
`;

export const StyledSwitch = styled(Switch)<{ $width: string }>`
  ${(props) =>
    FormElementCSS({
      width: props.$width,
      mdBreakpoint: props.theme.breakpoints.md,
    })}
  left: -10px;
`;
