import DISTRICTS from './districts';
import DIAL_CODES from './dialCodes';
import PAGES from './pages';
import CURRENCIES from './currencies';
import CURRENCY_CODES from './currencyCodes';
import LANGUAGES from './languages';
import {
  USER_TYPES,
  APPOINTMENT_STATUS,
  APPOINTMENT_TYPES,
  COMMISSION_TYPES,
  PARAMETER_KEYS,
  FIELDS_TO_TRACK_CHANGES,
  CHAT_STATUS,
  TRANSPLANT_TYPES,
  BUSINESS_UNIT_STATUS,
  VOUCHER_PACK_ASSOCIATION_TYPES,
  VOUCHER_STATUS,
  ATTRIBUTION_RULE_SOURCE,
} from './enum';
import { IMAGE_MIME_TYPES, XLS_MIME_TYPES } from './mimeTypes';

const CONSTANTS = {
  DEV: process.env.REACT_APP_ENV_MODE === 'development',
  GOOGLE_RECAPTCHA_SITEKEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY,
  LP_URL: process.env.REACT_APP_LP_URL,
  BACKEND_HOST: process.env.REACT_APP_BACKEND_HOST || 'localhost:4000',
  USER_TYPES: USER_TYPES,
  APPOINTMENT_STATUS: APPOINTMENT_STATUS,
  APPOINTMENT_TYPES: APPOINTMENT_TYPES,
  COMMISSION_TYPES: COMMISSION_TYPES,
  TRANSPLANT_TYPES: TRANSPLANT_TYPES,
  PARAMETER_KEYS: PARAMETER_KEYS,
  CHAT_STATUS: CHAT_STATUS,
  BUSINESS_UNIT_STATUS: BUSINESS_UNIT_STATUS,
  VOUCHER_PACK_ASSOCIATION_TYPES: VOUCHER_PACK_ASSOCIATION_TYPES,
  PAGES: PAGES,
  DISTRICTS: DISTRICTS,
  FIELDS_TO_TRACK_CHANGES,
  DEFAULT_ERROR_MESSAGE: 'Algo inesperado ocorreu. Por favor, tente mais tarde',
  TABLE_ROWS_PER_PAGE_OPTIONS: [10, 25, 50, 100],
  DEFAULT_TABLE_ROWS_PER_PAGE: 50,
  DIAL_CODES: DIAL_CODES,
  CURRENCIES: CURRENCIES,
  CURRENCY_CODES: CURRENCY_CODES,
  LANGUAGES: LANGUAGES,
  DEFAULT_BUSINESS_UNIT: 'PT',
  DEFAULT_LNG: 'pt',
  DEFAULT_LOGIN_REDIRECT_PATH: '/dashboard',
  REFRESH_TOKEN_EXPIRATION_TIME: 24, //hours
  IMAGE_MIME_TYPES,
  XLS_MIME_TYPES,
  ATTRIBUTION_RULE_SOURCE,
  VOUCHER_STATUS,
};

export default Object.freeze(CONSTANTS);
