import {
  forwardRef,
  useEffect,
  useState,
  FC,
  HTMLProps,
  Ref,
  SyntheticEvent,
  useCallback,
} from 'react';

import { CalendarIcon } from '@/icons';
import { IDatePicker } from './DatePicker.types';
import InputBase from '@/components/InputBase';

import {
  StyledDatePicker,
  StyledFormComponentWrapper,
} from './DatePicker.styles';

const CustomInput = forwardRef(
  (
    props: HTMLProps<HTMLInputElement> & { error: boolean; name: string },
    _: Ref<HTMLInputElement>
  ) => {
    const { name, error, ...rest } = props;

    return (
      <InputBase
        name={props.name}
        error={error}
        icon={<CalendarIcon />}
        rightIcon
        noFootnote
        {...rest}
      />
    );
  }
);

const DatePicker: FC<IDatePicker> = ({
  error,
  footnote,
  tooltip,
  required,
  label,
  name,
  date,
  noFootnote = false,
  onChange,
  className,
  ...props
}) => {
  const [dateState, setDateState] = useState<Date>(date || new Date());

  const handleChange = useCallback(
    (date: Date | null, event: SyntheticEvent<any, Event> | undefined) => {
      if (date) setDateState(date);
      onChange && onChange(date, event);
    },
    [onChange]
  );

  useEffect(() => {
    if (date) setDateState(date);
  }, [date, setDateState]);

  return (
    <StyledFormComponentWrapper
      required={required}
      tooltip={tooltip}
      footnote={footnote}
      label={label}
      name={name}
      error={error}
      noFootnote={noFootnote}
      className={className}
    >
      <StyledDatePicker
        selected={dateState}
        onChange={handleChange}
        dateFormat="dd/MM/yyyy"
        customInput={<CustomInput error={!!error} name={name} />}
        {...props}
      />
    </StyledFormComponentWrapper>
  );
};

export default DatePicker;
