import styled from 'styled-components';
import Switch from '@mui/material/Switch';
import FormComponentWrapper from '@/components/FormComponentWrapper';

export const StyledFormComponentWrapper = styled(FormComponentWrapper)<{
  $checked?: boolean;
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: auto;
  > label {
    width: 100%;
    text-align: left;
    margin-left: 9px;
    margin-bottom: 0;
    opacity: ${(props) => (props.$checked ? 1 : 0.5)};
  }
  > div {
    width: 100%;
  }
`;

export const StyledSwitch = styled(Switch)`
  && {
    margin-right: 5px;
    color: ${(props) => props.theme.palette.common.secundaryText};

    .Mui-checked {
      color: ${(props) => props.theme.palette.common.main};
      border: 0;
      .MuiSwitch-thumb {
        background-image: ${(props) => props.theme.palette.primaryGradient};
        background-color: transparent;
      }
    }

    .Mui-checked + .MuiSwitch-track {
      background-color: #c4c4c4;
    }
    .MuiSwitch-thumb {
      background-color: ${(props) => props.theme.palette.common.secundaryText};
    }
    .MuiSwitch-track {
      background-color: #c4c4c4;
    }
  }
`;
