import styled from 'styled-components';

const NotFoundContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  max-width: ${(props) => props.theme.breakpoints.xl}px;
  width: 100%;
  margin: 0 auto;

  height: calc(100vh - 70px - 30px);
`;

const NotFoundWrapper = styled.div`
  padding-bottom: 100px;
  text-align: center;
`;

const Error = styled.h1`
  color: ${(props) => props.theme.palette.common.secundaryText};
  font-size: 4em;
`;
const ErrorText = styled.p`
  color: ${(props) => props.theme.palette.common.secundaryText};
`;

export { NotFoundContainer, NotFoundWrapper, Error, ErrorText };
