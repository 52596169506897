import styled from 'styled-components';

export const DataLoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 160px);
  margin-top: 50px;
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.common.greyBorder};
`;
