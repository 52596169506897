import styled from 'styled-components';

export const InputBaseStyled = styled.input<{
  $error?: boolean;
  $icon: boolean;
  $rightIcon: boolean;
}>`
  position: relative;
  width: 100%;
  border: 1px solid
    ${(props) =>
      props.$error
        ? props.theme.palette.red
        : props.theme.palette.common.greyBorder};
  border-radius: 4px;
  padding: 11.5px;
  padding-left: ${(props) =>
    props.$icon && !props.$rightIcon ? 'calc(18px + 16px)' : '9px'};
  padding-right: ${(props) =>
    props.$icon && props.$rightIcon ? 'calc(18px + 16px)' : '9px'};

  &::placeholder {
    color: ${(props) => props.theme.palette.common.subText};
  }

  // fix ios safari zoom on focus
  font-size: 16px;
  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 14px;
  }
`;

export const WrapperIcon = styled.div<{
  $rightIcon: boolean;
}>`
  position: absolute;
  top: calc(50% - 9px);
  left: ${(props) => (props.$rightIcon ? 'initial' : '9px')};
  right: ${(props) => (props.$rightIcon ? '9px' : 'initial')};
  z-index: 1;
  svg {
    height: 20px;
    fill: ${(props) => props.theme.palette.common.subText};
    &:hover {
      fill: ${(props) => props.theme.palette.common.mainDark};
    }
  }
`;

export const InnerContent = styled.div`
  position: relative;
`;
