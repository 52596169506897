import CircularProgress from '@mui/material/CircularProgress';
import { DataLoadingWrapper } from './DataLoading.styles';

const DataLoading = () => {
  return (
    <DataLoadingWrapper>
      <CircularProgress />
    </DataLoadingWrapper>
  );
};

export default DataLoading;
