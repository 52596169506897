import { withPromptProps } from '@/hocs/withPrompt/withPrompt.types';
import useAxiosPrivate from '@/hooks/AxiosPrivate';
import {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as S from './VoucherPacksEdit.styles';
import {
  FormActions,
  FormBaseButton,
  FormPrimaryButton,
  FormRow,
  FormTitle,
} from '@/styles/shared';
import { IVoucherPack, IVoucherPackEdit } from '@/types';
import { handleYupSubmissionError } from '@/utils/Yup';
import { Schema } from './VoucherPacksEdit.schemas';
import { handleAxiosSubmissionError } from '@/utils/Axios';
import emitAlert from '@/utils/Toastify/Toastify';
import DataLoading from '@/components/DataLoading';
import DashboardNotFound from '@/components/DashboardNotFound';

const VoucherPackEdit: FC<withPromptProps> = ({ setShowPrompt }) => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [touched, setTouched] = useState<boolean>(false);

  const [voucherPack, setVoucherPack] = useState<IVoucherPack | null>(null);
  const [values, setValues] = useState<IVoucherPackEdit>({});

  const handleInputChange = useCallback(
    (
      inputName: keyof IVoucherPackEdit,
      value: IVoucherPackEdit[keyof IVoucherPackEdit]
    ) => {
      setValues((prevValues) => {
        return {
          ...prevValues,
          [inputName]: value,
        };
      });
      setErrors({});
      setShowPrompt && setShowPrompt(true);
      setTouched(true);
    },
    [setShowPrompt]
  );

  const getData = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await axiosPrivate.get<{
        payload: { voucherPack: IVoucherPack };
      }>(`/api/voucher-packs/${id}`);

      setVoucherPack(data?.payload?.voucherPack);
    } catch (e) {
      handleAxiosSubmissionError(e);
    } finally {
      setLoading(false);
    }
  }, [axiosPrivate, id]);

  const handlePostRequest = async (validatedData: IVoucherPackEdit) => {
    try {
      const { data } = await axiosPrivate.put<{
        payload: { success: boolean };
      }>(`/api/voucher-packs/${id}`, validatedData);

      if (data?.payload?.success) {
        emitAlert({
          message: t('voucher-pack.edit-success-message'),
          type: 'success',
          duration: 2500,
        });

        navigate(-1);
      }
    } catch (err: any) {
      handleAxiosSubmissionError(err);
      setShowPrompt && setShowPrompt(true);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    try {
      e.preventDefault();

      if (!touched) return;

      setSubmitting(true);

      const validatedData = await Schema.validate(values, {
        abortEarly: false,
      });

      handlePostRequest(validatedData);
      setShowPrompt && setShowPrompt(false);
    } catch (errors) {
      handleYupSubmissionError(errors, t, setErrors);
      setSubmitting(false);
      setShowPrompt && setShowPrompt(true);
    }
  };

  const voucherMinExpires = useMemo(
    () =>
      voucherPack?.expiresAt && new Date(voucherPack?.expiresAt) > new Date()
        ? new Date(voucherPack?.expiresAt)
        : new Date(),
    [voucherPack]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  if (!voucherPack && loading) {
    return <DataLoading />;
  }

  if (!voucherPack && !loading) {
    return <DashboardNotFound />;
  }

  return (
    <S.Wrapper>
      <form onSubmit={handleSubmit} noValidate>
        <S.ContentBox>
          <FormTitle>{t('voucher-pack.edit-title')}</FormTitle>
          <FormRow>
            <S.StyledDatePicker
              $width="calc(50% - 10px)"
              name="expiresAt"
              label={t('voucher-pack.expires-at')}
              placeholderText={t('voucher-pack.expires-at')}
              selected={
                values.expiresAt ??
                (voucherPack?.expiresAt
                  ? new Date(voucherPack?.expiresAt)
                  : null)
              }
              onChange={(date) => date && handleInputChange('expiresAt', date)}
              error={!!errors?.expiresAt}
              footnote={errors?.expiresAt}
              autoComplete="off"
              minDate={voucherMinExpires}
            />
          </FormRow>
          <FormRow>
            <S.StyledSwitch
              $width="calc(50% - 10px)"
              name="newPartnersOnCategory"
              label={t('voucher-pack.new-partners-on-category')}
              checked={values.newPartnersOnCategory}
              error={!!errors?.newPartnersOnCategory}
              footnote={errors?.newPartnersOnCategory}
              onChange={(_, checked) =>
                handleInputChange('newPartnersOnCategory', checked)
              }
            />
          </FormRow>
          <FormRow>
            <S.StyledSwitch
              $width="calc(50% - 10px)"
              name="partnersWithoutVouchers"
              label={t('voucher-pack.partners-without-vouchers')}
              checked={values.partnersWithoutVouchers}
              error={!!errors?.partnersWithoutVouchers}
              footnote={errors?.partnersWithoutVouchers}
              onChange={(_, checked) =>
                handleInputChange('partnersWithoutVouchers', checked)
              }
            />
          </FormRow>
        </S.ContentBox>
        <FormActions>
          <FormBaseButton onClick={() => navigate(-1)} type="button">
            {t('common.cancel')}
          </FormBaseButton>
          <FormPrimaryButton disabled={submitting || !touched} type="submit">
            {t('common.save')}
          </FormPrimaryButton>
        </FormActions>
      </form>
    </S.Wrapper>
  );
};

export default VoucherPackEdit;
