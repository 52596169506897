import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FormComponentWrapper from '@/components/FormComponentWrapper';

export const StyledFormComponentWrapper = styled(FormComponentWrapper)`
  max-width: initial;
`;

export const StyledDatePicker = styled(DatePicker)``;
