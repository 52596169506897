import React from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '@/components/PrimaryButton';
import { useTranslation } from 'react-i18next';
import { IDashboardNotFoundProps } from './DashboardNotFound.types';
import {
  NotFoundContainer,
  NotFoundWrapper,
  Error,
  ErrorText,
} from './DashboardNotFound.styles';

const DashboardNotFound: React.FC = ({
  publicLayout,
}: IDashboardNotFoundProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <NotFoundContainer>
      <NotFoundWrapper>
        <Error>404</Error>
        <ErrorText>{t('dashboard.page-not-found')}</ErrorText>
        <PrimaryButton onClick={() => navigate('/dashboard')}>
          {t('dashboard.back')}
        </PrimaryButton>
      </NotFoundWrapper>
    </NotFoundContainer>
  );
};

export default DashboardNotFound;
