import { InputBaseStyled, WrapperIcon, InnerContent } from './InputBase.styles';
import { IInputBase } from './InputBase.types';
import FormComponentWrapper from '@/components/FormComponentWrapper';
import { FC } from 'react';

const InputBase: FC<IInputBase> = ({
  error,
  footnote,
  tooltip,
  required,
  label,
  name,
  value,
  onChange,
  icon,
  rightIcon = false,
  noFootnote = false,
  className,
  forwardRef,
  onClickIcon,
  ...props
}) => {
  return (
    <FormComponentWrapper
      required={required}
      tooltip={tooltip}
      footnote={footnote}
      label={label}
      name={name}
      error={error}
      className={className}
      noFootnote={noFootnote}
    >
      <InnerContent>
        {icon && (
          <WrapperIcon $rightIcon={rightIcon} onClick={onClickIcon}>
            {icon}
          </WrapperIcon>
        )}
        <InputBaseStyled
          $error={error}
          $icon={!!icon}
          $rightIcon={rightIcon}
          name={name}
          required={required}
          value={value}
          onChange={onChange}
          ref={forwardRef}
          {...props}
        />
      </InnerContent>
    </FormComponentWrapper>
  );
};

export default InputBase;
